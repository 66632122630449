<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="780px">
                <v-card :style="($vuetify.breakpoint.mdAndDown) ? 'width:auto;' : 'min-width:320px;'">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto;">
                        <div style="min-height:10px;"></div>
                         <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">UUID:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ item.uuid }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Trinity IP address:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ item.ip }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Sales location:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small color="#5D4037">{{ item.business_premise_name }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Cashier:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <!--<span class="font-weight-bold"><v-chip label dark small >{{ item.cashier_nr }}</v-chip></span>-->
                                    <span class="font-weight-bold">{{ item.cashier_nr }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Last update (timestamp):</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small :color="lastUpdateColor()">{{ item.last_update }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Alarm:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                        <!--<span style="color:green;font-weight:500;font-size:1.1em;">{{ alarmHtmlStr() }}</span>-->
                                        <span v-html="alarmHtmlStr()"></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <!--<v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Traffic:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.coins }}&nbsp;€</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>-->

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Coin exchanger sum:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.chng }}&nbsp;€</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">CE last empty:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.oldchng }}&nbsp;€</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Paper level:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.pfill }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <br><br>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Last invoice:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <!--<span class="font-weight-bold">{{ lastInvoice.invoice_nr }}</span>-->
                                    <span v-html="lastInvoiceHtml(lastInvoice)"></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Invoice timestamp:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <!--<span class="font-weight-bold">{{ lastInvoice.date_time }}</span>-->
                                    <span v-html="lastInvoiceTimestampHtml(lastInvoice)"></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Amount:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold">{{ lastInvoice.amount}}&nbsp;€</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Tax base:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold">{{ lastInvoice.tax_base }} &nbsp;€</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Tax amount:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold">{{ lastInvoice.tax_amount }}&nbsp;€</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">ZKI:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold"><v-chip label dark small>{{ lastInvoice.zoi }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">JIR:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold"><v-chip label dark small>{{ lastInvoice.eor }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Card payment:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold"><v-chip label dark small :color="cardPaymentColor(lastInvoice.card_payment)">{{ (lastInvoice.card_payment !== null && lastInvoice.card_payment == 1) ? 'YES' : 'NO' }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Payment method:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold"><v-chip label dark small :color="paymentMethodColor(lastInvoice.payment_method)">{{ paymentMethodName(lastInvoice.payment_method) }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Transaction ID:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="lastInvoice !== null">
                                    <span class="font-weight-bold"><v-chip label dark small>{{ lastInvoice.transaction_id }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="lastInvoice !== null"></div>


                                <!--
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Traffic:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.last_update }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                -->

                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>

</template>

<script>
import { formatDate, randomPinNumber, getCurrentDate, getHoursDiff, getMinutesDiff } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'

export default {
    name: 'telemetry-info-dialog',
    data: () => ({
        valid: false,
        item: {},
        dialog: false,
        loading: false,
        note: null,
        formTitle: "",
        cancelText: 'Close',
        event_timestamp: null,
        currentDate: null,
        cashier_info: {},
        

        
    }),

    watch : {

    },

    computed: {

        lastInvoice() {
            let invoice = null

            if(Object.prototype.hasOwnProperty.call(this.item, 'cashier_info') && this.item.cashier_info !== null) {
                invoice = this.item.cashier_info.last_invoice
            }

            return invoice
        }
    },

    methods: {
        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        setFormTitle() {
            this.formTitle = "";
            if(this.item.cashier_name !== undefined) {
                this.formTitle = this.formTitle + this.item.cashier_name
            }
        },

        cancel() {
            this.dialog = false
            this.item = {}
        },

        lastUpdateColor() {
            let wl = this.item.last_update_warning_level

            if(wl == 1) {
                return 'success'
            }

            if(wl == 2) {
                return 'warning'
            }

            if(wl == 3) {
                return 'error'
            }

            return "success"
        },

        alarmHtmlStr() {
            let htmlStr = "";
            if(Object.prototype.hasOwnProperty.call(this.item, 'errors')) {
                let errors = this.item.errors
                if(errors.length > 0) {
                    errors.forEach(error => {
                        htmlStr +=  "<span style='color:red;font-weight:400;font-size:1.1em;'>" + error + ";" + "</span><br>"
                    })
                } else {
                    htmlStr = "<span style='color:green;font-weight:400;font-size:1.1em;'>No errors found.</span>"
                }
            } 
            return htmlStr;
        },

        paymentMethodName(paymentMethodId) {
            if(paymentMethodId == 1) {
                return "CREDIT CARD";
            }

            if(paymentMethodId == 2) {
                return "FREE VEND";
            }

            if(paymentMethodId == 3) {
                return "CASH"
            }

            return ""
        },

        paymentMethodColor(paymentMethod) {
           if(paymentMethod == 1) {
               return "error"
           }

           if(paymentMethod == 2) {
               return "#6D4C41"
           }

           return "success";
       },

       cardPaymentColor(cardPayment) {
           if(cardPayment == 1) {
               return "success"
           }

           return "error"
       },

       lastInvoiceHtml(invoice) {
            let htmlContent = "";
            let lastInvoice24 = false;
            let lastInvoice2430 = false
            let lastInvoice30 = false
            let invoiceDateTimeStr = invoice.date_time

            let currentDate = new Date()

            let day = (currentDate.getDate()).toString().padStart(2, "0");
            let month = (currentDate.getMonth() +1).toString().padStart(2, "0")
            let year = currentDate.getFullYear();

            let currentDateTime = year + "-" + month + "-" + day + " " + currentDate.getHours().toString().padStart(2, "0") + ":" + currentDate.getMinutes().toString().padStart(2, "0") + ":" + currentDate.getSeconds().toString().padStart(2, "0");

            let dih = getHoursDiff(currentDateTime, invoiceDateTimeStr)

            if(dih < 24) {
                lastInvoice24 = true
            }

            else if(dih >= 24 && dih <= 30) {
                lastInvoice2430 = true
            }
            else {
                lastInvoice30 = true
            }

            if(lastInvoice24) {
                htmlContent = '<span class="font-weight-bold" style="color:#83D932;">' + invoice.invoice_nr + '</span>';
            }   

            else if(lastInvoice2430) {
                htmlContent = '<span class="font-weight-bold" style="color:#CD8900;">' + invoice.invoice_nr + '</span>';
            }

            else if(lastInvoice30) {
                htmlContent = '<span class="font-weight-bold" style="color:#FF0000;">' + invoice.invoice_nr + '</span>';
            }

            else {
                htmlContent = '<span class="font-weight-bold">' + invoice.invoice_nr + '</span>';
            }

            return htmlContent;

        },

       lastInvoiceTimestampHtml(invoice) {
        let htmlContent = "";
            let lastInvoice24 = false;
            let lastInvoice2430 = false
            let lastInvoice30 = false
            let invoiceDateTimeStr = invoice.date_time

            let currentDate = new Date()

            let day = (currentDate.getDate()).toString().padStart(2, "0");
            let month = (currentDate.getMonth() +1).toString().padStart(2, "0")
            let year = currentDate.getFullYear();

            let currentDateTime = year + "-" + month + "-" + day + " " + currentDate.getHours().toString().padStart(2, "0") + ":" + currentDate.getMinutes().toString().padStart(2, "0") + ":" + currentDate.getSeconds().toString().padStart(2, "0");

            let dih = getHoursDiff(currentDateTime, invoiceDateTimeStr)

            if(dih < 24) {
                lastInvoice24 = true
            }

            else if(dih >= 24 && dih <= 30) {
                lastInvoice2430 = true
            }
            else {
                lastInvoice30 = true
            }

            if(lastInvoice24) {
                htmlContent = '<span class="font-weight-bold" style="color:#83D932;">' + invoice.date_time + '</span>';
            }   

            else if(lastInvoice2430) {
                htmlContent = '<span class="font-weight-bold" style="color:#CD8900;">' + invoice.date_time + '</span>';
            }

            else if(lastInvoice30) {
                htmlContent = '<span class="font-weight-bold" style="color:#FF0000;">' + invoice.date_time + '</span>';
            }

            else {
                htmlContent = '<span class="font-weight-bold">' + invoice.date_time + '</span>';
            }

            return htmlContent;
       }
    },

    created() {
        var vm = this;
        this.currentDate = getCurrentDate()

        this.setParametersData();

        EventBus.$on('VIEW_TELEMETRY_ITEM_DIALOG', function(data) {
            //window.console.log(data)
            vm.item = data
            vm.cashier_info = data.cashier_info
            vm.setFormTitle();
            vm.dialog = true;

        });
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off('VIEW_TELEMETRY_ITEM_DIALOG')
    },

    destroyed() {

    }

}

</script>

<style scoped>
    .v-card__title {
        background-color: #f5f5f5 !important;
    }

    span.detail-name-label {
        color: "#000" !important;
    }
</style>